import type { Note } from "~/server/db/schema";
import { Share, Pencil, Trash, Check, X } from "@phosphor-icons/react/dist/ssr"
import { useState } from "react";
import { format } from 'date-fns';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
  } from "~/components/ui/alert-dialog";
  import { toast } from "sonner";
  import { hashStringToColor, getDarkerColor } from "~/lib/utils";

export function Note({ note }: { note: Note }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedTitle, setEditedTitle] = useState(note.title ?? '');
    const [editedContent, setEditedContent] = useState(note.content ?? '');

    const formattedDate = note?.createdAt 
    ? format(new Date(note.createdAt), 'MM/d/yyyy')
    : 'Date not available';

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            const response = await fetch('/api/notes', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: note.id,
                    title: editedTitle,
                    content: editedContent,
                }),
            });

            if (response.ok) {
                setIsEditing(false);
                toast.success('Note updated successfully');
                // Dispatch an event to update the parent component
                window.dispatchEvent(new CustomEvent("noteUpdated", { 
                    detail: { ...note, title: editedTitle, content: editedContent } 
                }));
            } else {
                toast.error('Failed to update note');
            }
        } catch (error) {
            console.error('Error updating note:', error);
            toast.error('Error updating note');
        }
    };

    const handleCancel = () => {
      setIsEditing(false);
      setEditedTitle(note.title ?? '');
      setEditedContent(note.content ?? '');
  };

    const handleShare = () => {
        const noteUrl = `${window.location.origin}/n/${note.id}`;
        navigator.clipboard.writeText(noteUrl).then(() => {
            toast.success('Note URL copied to clipboard');
        }).catch((err) => {
            console.error('Failed to copy: ', err);
            toast.error('Failed to copy URL');
        });
    };

    return (
        <div className="p-2 rounded-sm flex flex-col gap-1">
            {isEditing ? (
                <>
                    <input
                        type="text"
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                        className="text-base font-medium mb-2 p-1 border rounded"
                    />
                    <textarea
                        value={editedContent}
                        onChange={(e) => setEditedContent(e.target.value)}
                        className="text-sm text-gray-500 whitespace-pre-wrap p-1 border rounded"
                        rows={8}
                    />
                </>
            ) : (
                <>
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="text-base font-medium">{note.title}</h2>
                      <p className="text-xs text-zinc-400">{formattedDate}</p>
                    </div>
                    <p className="text-sm text-gray-500 whitespace-pre-wrap">{note.content}</p>
                </>
            )}
            <div className="flex justify-between">
                <div className="flex flex-wrap gap-2 mt-1">
                    {note.tags?.map((tag) => {
                            const bgColor = hashStringToColor(tag);
                            const textColor = getDarkerColor(bgColor);
                            return (
                                <div key={tag}> 
                                    <span 
                                        className="capitalize rounded-full px-3 py-1 text-xs font-medium"
                                        style={{ backgroundColor: bgColor, color: textColor }}
                                    >
                                        {tag}
                                    </span>
                                </div>
                            );
                        })}
                </div>
                <div className="flex gap-1">
                  
                      {isEditing ? (
                          <>
                              <button 
                                  className="text-sm text-gray-400"
                                  onClick={handleSave}
                              >
                                  <Check className="w-5 h-5 md:w-4 md:h-4 hover:text-green-500" />
                                  <span className="sr-only">Save</span>
                              </button>
                              <button 
                                  className="text-sm text-gray-400"
                                  onClick={handleCancel}
                              >
                                  <X className="w-5 h-5 md:w-4 md:h-4 hover:text-red-500" />
                                  <span className="sr-only">Cancel</span>
                              </button>
                          </>
                      ) : (
                        <div className="flex gap-1">
                          <button 
                              className="text-sm text-gray-400"
                              onClick={handleEdit}
                          >
                              <Pencil className="w-5 h-5 md:w-4 md:h-4 hover:text-gray-800" />
                              <span className="sr-only">Edit</span>
                          </button>
                          <button 
                            className="text-sm text-gray-400"
                              onClick={handleShare}
                          >
                              <Share className="w-5 h-5 md:w-4 md:h-4 hover:text-gray-800" />
                              <span className="sr-only">Share</span>
                          </button>
                          <DeleteNoteDialog note={note} />
                          </div>
                      )}
                </div>
            </div>
        </div>
    )
}

function DeleteNoteDialog({ note }: { note: Note }) {
    const handleDelete = async () => {
        try {
          const response = await fetch('/api/notes', {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: note.id }),
          });
          
          if (response.ok) {
            window.dispatchEvent(new CustomEvent("noteDeleted", { detail: note }));
            toast.success('Note deleted successfully');
          } else {
            console.error('Failed to delete note');
          }
        } catch (error) {
          console.error('Error deleting note:', error);
        }
      };

    return (
        <AlertDialog>
          <AlertDialogTrigger asChild>
          <button className="text-sm text-gray-400">
                <Trash className="w-5 h-5 md:w-4 md:h-4 hover:text-red-500" />
                <span className="sr-only">Delete</span>
            </button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Delete Note</AlertDialogTitle>
              <AlertDialogDescription>
                This action cannot be undone. This will permanently delete your note.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={handleDelete} className="bg-red-500 hover:bg-red-600 text-white">Delete</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
    )
}