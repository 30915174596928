import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function hashStringToColor(str: string): string {
  str = str.toLowerCase();
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = hash % 360;
  return `hsl(${hue}, 70%, 90%)`;
}

export function getDarkerColor(color: string): string {
  const hsl = color.match(/\d+/g)?.map(Number) ?? [0, 0, 0];
  return `hsl(${hsl[0] ?? 0}, ${hsl[1] ?? 0}%, ${Math.max((hsl[2] ?? 0) - 50, 10)}%)`;
}