"use client"

import { useState, useEffect } from "react";
import { Note as NoteType } from "~/server/db/schema";
import { Note as NoteComponent } from "./note";
import { motion, AnimatePresence } from "framer-motion";
import {
    Alert,
    AlertDescription,
    AlertTitle,
  } from "~/components/ui/alert";
  import { Rocket, ArrowDown, Microphone, Tag, Lock, Robot, Crown } from "@phosphor-icons/react/dist/ssr";
import Link from "next/link";

export function Notes({ initialNotes, user, signInUrl }: { initialNotes: NoteType[], user: any, signInUrl: string }) {
    const [notes, setNotes] = useState<NoteType[]>(initialNotes);

    useEffect(() => {
        const handleNewNote = (event: CustomEvent<NoteType>) => {
            const newNote = event.detail;
            setNotes(prevNotes => [newNote, ...prevNotes]);
        };

        const handleNoteUpdated = (event: CustomEvent<NoteType>) => {
            const updatedNote = event.detail;
            setNotes(prevNotes => 
                prevNotes.map(note => 
                    note.id === updatedNote.id ? updatedNote : note
                )
            );
        };

        const handleNoteDeleted = (event: CustomEvent<NoteType>) => {
            const deletedNote = event.detail;
            setNotes(prevNotes => prevNotes.filter(note => note.id !== deletedNote.id));
        };

        window.addEventListener('newNote', handleNewNote as EventListener);
        window.addEventListener("noteUpdated", handleNoteUpdated as EventListener);
        window.addEventListener('noteDeleted', handleNoteDeleted as EventListener);
        return () => {
            window.removeEventListener('newNote', handleNewNote as EventListener);
            window.removeEventListener("noteUpdated", handleNoteUpdated as EventListener);
            window.removeEventListener('noteDeleted', handleNoteDeleted as EventListener);
        };
    }, []);

    return (
        <motion.div 
        className="flex flex-col mx-auto w-full max-w-2xl gap-4"
        layout
    >
            <AnimatePresence initial={false}>
                {!user.user && notes.length >= 3 && (
                    <motion.div
                        key="welcome"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                            <Alert className="mb-8">
                                <Rocket className="h-4 w-4" />
                                <AlertTitle>You've hit your limit without an account!</AlertTitle>
                                <AlertDescription className="text-sm pt-2">
                                    <Link href={signInUrl} className="underline">Create a free <strong>mmbl</strong> account</Link> to add custom tags that automatically get applied during note saving and add up to 100 notes!
                                    <br /><br />
                                    Pay a one-time fee of $39 for unlimited notes, searching and tagging.
                                </AlertDescription>
                            </Alert>
                    </motion.div>
                )}
                {notes.length == 0 && (
                    <motion.div
                        key="welcome"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="p-4 rounded-md"
                        >
                            <div className="max-w-lg mx-auto">
                                <h1 className="text-2xl font-bold text-center mb-6">Capture. Organise. Simplify.</h1>
                                <div className="flex items-center mb-8 gap-5">
                                    <Microphone className="h-6 w-6 flex-shrink-0" />
                                    <p className="text-zinc-700 text-sm">
                                        Whether it's a brilliant idea, a to-do list, or a random thought, <span className="font-semibold">quickly record voice notes</span> and let us handle the rest. Our automated system effortlessly <span className="font-semibold">transcribes, organises, and tags your notes</span>, so you can focus on what really matters.
                                    </p>
                                </div>

                                <div className="flex items-center mb-8 gap-5">
                                    <Tag className="h-6 w-6 flex-shrink-0" />
                                    <div>
                                        <h2 className="font-semibold mb-1 text-sm">Tag Your Way to Clarity</h2>
                                        <p className="text-zinc-700 text-sm">
                                            Define your own tags, and watch as your voice notes are automatically sorted into the categories you care about. Need to quickly reference that blog post idea or health update? It's all just a search away.
                                        </p>
                                    </div>
                                </div>

                                <div className="flex items-center mb-8 gap-5">
                                    <Lock className="h-6 w-6 flex-shrink-0" />
                                    <div>
                                        <h2 className="font-semibold mb-1 text-sm">Your Thoughts, Your Privacy</h2>
                                        <p className="text-zinc-700 text-sm">
                                            Your voice notes are yours alone. No recordings are ever persisted on our servers. We prioritise privacy and security, ensuring your data stays safe.
                                        </p>
                                    </div>
                                </div>

                                <div className="flex items-center mb-8 gap-5">
                                    <Robot className="h-6 w-6 flex-shrink-0" />
                                    <div>
                                        <h2 className="font-semibold mb-1 text-sm">mmbl at Your Service</h2>
                                        <p className="text-zinc-700 text-sm">
                                            Looking for a summary of a past note? Want an email drafted based on your voice recordings? Ask mmbl to do the heavy lifting—whether it's summarising, organising, or helping you create.
                                        </p>
                                    </div>
                                </div>

                                <div className="flex items-center mb-8 gap-5">
                                    <Crown className="h-6 w-6 flex-shrink-0" />
                                    <div>
                                        <h2 className="font-semibold text-sm mb-1 bg-gradient-to-r from-orange-400 to-pink-600 bg-clip-text text-transparent">Sign up to Pro</h2>
                                        <p className="text-zinc-700 text-sm">
                                            Unlock unlimited notes, tags, and more with a one-time fee of $39.
                                        </p>
                                    </div>
                                </div>

                                <p className="font-medium text-sm">
                                    Join thousands of users <span className="underline font-semibold">for free</span> who trust us to keep their ideas organised, safe, and beautifully stored.
                                </p>
                            </div>

                            <div className="mt-12 text-center font-bold">
                                Try mmbl without signing up!
                                <div className="flex justify-center mt-8">
                                    <ArrowDown className="h-8 w-8 animate-bounce" />
                                </div>
                            </div>
                        </motion.div>
                )}
                {notes.map((note) => (
                    <motion.div
                        key={note.id}
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <NoteComponent note={note} />
                    </motion.div>
                ))}
            </AnimatePresence>
        </motion.div>
    );
}